import React, { useRef } from "react";

import TimeListCard from "./TimeListCard";
import "./TimeList.css";

const TimeList = (props) => {
  const rowID = useRef(0);

  if (!props.data) {
    return (
      <div className="timelist">
        <table className="table-timelist">
          <tbody className="table-timelist__body">
            <tr>
              <td className="table-timelist__body-header-item">#</td>
              <td className="table-timelist__body-header-item">Time</td>
              <td className="table-timelist__body-header-item">Ao5</td>
              <td className="table-timelist__body-header-item">Mo5</td>
              <td className="table-timelist__body-header-item">Mo12</td>
            </tr>
          </tbody>
        </table>
        <div className="timelist-info">
          <p>
            Welcome to geTimer.Net
          </p>
          <hr />
          <ul>
            <li>
              <b>Desktop shortcuts</b>
            </li>
            <li>[space]: start/stop</li>
            <li>[del]: delete most recent time</li>
            <li>[d]: DNF - did not finish</li>
            <li>[p]: 2 second penalty</li>
            <li>[r]: reset session (delete all times)</li>
            <li>[h]: hide/unhide timer</li>
          </ul>


        </div>
      </div>
    );
  }

  // let myTimeList = null;
  // let timeData = [];
  // if (!Array.isArray(props.data)) {  //this actually never gets called now because  is always sent
  //   //https://dev.to/arikaturika/use-this-trick-to-map-over-single-objects-in-javascript-38nb
  //   myTimeList = <h4> props.data.item </h4>;
  //   timeData = [props.data.item];
  // } else {

  const elementHandler = (id) => {
    console.log("The id of this row is: " + id.target.id);
  };

  const tma = props.data;

  // Fastest Time
  let currMin = 99999999999;
  let currIndex = -1;
  for (let i = 0; i < tma.length; i++) {
    let tm = tma[i].item + (tma[i].penalty ? 2 : 0);

    if (tm < currMin && !tma[i].dnf) {
      currMin = tma[i].item;
      currIndex = tma[i].id;
    }
  }

  // Get WCA Average of 5
  const wcaFive = (xa) => {
    let dnfCount = 0,
      sortedX = [];
    for (let i = 0; i < 5; i++) {
      if (xa[i].dnf) {
        sortedX.push(86400);
        dnfCount++;
      } else {
        sortedX.push(xa[i].item + (xa[i].penalty ? 2 : 0));
      }
    }
    // console.log(sortedX)
    if (dnfCount < 2) {
      sortedX.sort(function (a, b) {
        return a - b;
      });
      let ao5 = (sortedX[1] + sortedX[2] + sortedX[3]) / 3;
      // console.log(ao5)
      return +ao5;
    }
    if (dnfCount >= 2) {
      return "dnf";
    }
  };

  // 5-Period Moving Average (including Ao5)
  let ma5Min = 99999999999;
  let ma5Index = -1;
  let ao5Min = 99999999999;
  let ao5Index = -1;
  for (let i = tma.length - 1; i >= 0; i--) {
    let n = 0,
      sum = 0;
    let ma5 = 0;

    if (i < tma.length - 4) {
      //MA(5) requires this at least
      // sum =
      //   tma[i].item +
      //   tma[i + 1].item +
      //   tma[i + 2].item +
      //   tma[i + 3].item +
      //   tma[i + 4].item;
      //let tm = tma[i].item + (tma[i].penalty ? 2 : 0);

      if (!tma[i].dnf) {
        sum += tma[i].item + (tma[i].penalty ? 2 : 0);
        n += 1;
      }
      if (!tma[i + 1].dnf) {
        sum += tma[i + 1].item + (tma[i + 1].penalty ? 2 : 0);
        n += 1;
      }
      if (!tma[i + 2].dnf) {
        sum += tma[i + 2].item + (tma[i + 2].penalty ? 2 : 0);
        n += 1;
      }
      if (!tma[i + 3].dnf) {
        sum += tma[i + 3].item + (tma[i + 3].penalty ? 2 : 0);
        n += 1;
      }
      if (!tma[i + 4].dnf) {
        sum += tma[i + 4].item + (tma[i + 4].penalty ? 2 : 0);
        n += 1;
      }

      ma5 = sum / n;
      if (n !== 5) {
        ma5 = -1;
      }
      tma[i]["ma5"] = ma5; //adds new key/value pair to object[i]

      if (ma5 < ma5Min && !tma[i].dnf && ma5 > 0) {
        ma5Min = ma5;
        ma5Index = tma[i].id;
      }

      let ao5 = wcaFive([
        tma[i],
        tma[i + 1],
        tma[i + 2],
        tma[i + 3],
        tma[i + 4],
      ]);
      tma[i]["ao5"] = ao5; //adds new key/value pair to object[i]
      if(ao5 !== "dnf"){
        if (ao5 < ao5Min && ao5 > 0) {
          ao5Min = ao5;
          ao5Index = tma[i].id;
        }
      }

    } else {
      tma[i]["ao5"] = -1; //adds new key/value pair to object[i]
      tma[i]["ma5"] = -1; //adds new key/value pair to object[i]
    }
  }

  // 12-Period Moving Average
  let ma12Min = 99999999999;
  let ma12Index = -1;

  for (let i = tma.length - 1; i >= 0; i--) {
    let n = 0;
    let sum = 0;
    let ma12 = 0;
    if (i < tma.length - 11) {
      if (!tma[i].dnf) {
        sum += tma[i].item + (tma[i].penalty ? 2 : 0);
        n += 1;
      }
      if (!tma[i + 1].dnf) {
        sum += tma[i + 1].item + (tma[i + 1].penalty ? 2 : 0);
        n += 1;
      }
      if (!tma[i + 2].dnf) {
        sum += tma[i + 2].item + (tma[i + 2].penalty ? 2 : 0);
        n += 1;
      }
      if (!tma[i + 3].dnf) {
        sum += tma[i + 3].item + (tma[i + 3].penalty ? 2 : 0);
        n += 1;
      }
      if (!tma[i + 4].dnf) {
        sum += tma[i + 4].item + (tma[i + 4].penalty ? 2 : 0);
        n += 1;
      }
      if (!tma[i + 5].dnf) {
        sum += tma[i + 5].item + (tma[i + 5].penalty ? 2 : 0);
        n += 1;
      }
      if (!tma[i + 6].dnf) {
        sum += tma[i + 6].item + (tma[i + 6].penalty ? 2 : 0);
        n += 1;
      }
      if (!tma[i + 7].dnf) {
        sum += tma[i + 7].item + (tma[i + 7].penalty ? 2 : 0);
        n += 1;
      }
      if (!tma[i + 8].dnf) {
        sum += tma[i + 8].item + (tma[i + 8].penalty ? 2 : 0);
        n += 1;
      }
      if (!tma[i + 9].dnf) {
        sum += tma[i + 9].item + (tma[i + 9].penalty ? 2 : 0);
        n += 1;
      }
      if (!tma[i + 10].dnf) {
        sum += tma[i + 10].item + (tma[i + 10].penalty ? 2 : 0);
        n += 1;
      }
      if (!tma[i + 11].dnf) {
        sum += tma[i + 11].item + (tma[i + 11].penalty ? 2 : 0);
        n += 1;
      }

      ma12 = sum / n;
      if (n !== 12) {
        ma12 = -1;
      }
      tma[i]["ma12"] = ma12; //adds new key/value pair to object[i]
      if (ma12 < ma12Min && !tma[i].dnf && ma12 > 0) {
        ma12Min = ma12;
        ma12Index = tma[i].id;
      }
    } else {
      tma[i]["ma12"] = -1; //adds new key/value pair to object[i]
    }
  }

  const tableRows = tma.map((element, index, array) => {

    let td0Class = "table-timelist__row-item";
    if (element.id === currIndex) {
      td0Class = "table-timelist__row-item-highlight";
    }

    //Styling for average of 5
    let td5aClass = "table-timelist__row-item";
    if (element.id === ao5Index) {
      td5aClass = "table-timelist__row-item-highlight";
    }

    //Styling for mean of 5
    let td5Class = "table-timelist__row-item";
    if (element.id === ma5Index) {
      td5Class = "table-timelist__row-item-highlight";
    }

    //Styling for mean of 12
    let td12Class = "table-timelist__row-item";
    if (element.id === ma12Index) {
      td12Class = "table-timelist__row-item-highlight";
    }

 
    let adjTime = element.item;
    if(element.penalty){
      adjTime = element.item + 2;
      td0Class = td0Class + " penalty";
    } 
    if(element.dnf){
      adjTime = "dnf";
      td0Class = td0Class + " dnf";
    }

    if(element.ao5 === "dnf"){
      td5aClass = td5aClass + " dnf";
    }

    if(element.first){
      td0Class = td0Class + " first";
    }


    let idClass = "table-timelist__row-id"
    let item = element.item
    let target = element.target
    if(index < tma.length-1){
      target = array[index+1]['target']
      if(item < target){
        idClass = idClass + " target-met"
      }
    }




    
    

    return (
      <tr
        id={element.id}
        key={element.key}
        onClick={elementHandler}
        className="table-timelist__row"
      >
        <td id={element.id} className={idClass}>
          {element.id}
        </td>

        <td className={td0Class}>
        <TimeListCard id={element.id} tmSeconds={adjTime}></TimeListCard>
        </td>

        <td className={td5aClass}>
          <TimeListCard id={element.id} tmSeconds={element.ao5} />
        </td>

        <td className={td5Class}>
          <TimeListCard id={element.id} tmSeconds={element.ma5}></TimeListCard>
        </td>

        <td className={td12Class}>
          <TimeListCard id={element.id} tmSeconds={element.ma12}></TimeListCard>
        </td>
      </tr>
    );
  });

//  console.log(tma)
  // const timeData = props.data.map((element) => parseFloat(element.item));

  return (
    <div className="timelist">
      <table className="table-timelist">
        <tbody className="table-timelist__body">
          <tr>
            <td className="table-timelist__body-header-item">#</td>
            <td className="table-timelist__body-header-item">Time</td>
            <td className="table-timelist__body-header-item">Ao5</td>
            <td className="table-timelist__body-header-item">Mo5</td>
            <td className="table-timelist__body-header-item">Mo12</td>
          </tr>
          {tableRows}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(TimeList);
// export default TimeList
