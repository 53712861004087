import React, { useState, useEffect, useCallback } from "react";

import Overlay from "./components/UI/Overlay";
import Identity from "./components/Identity";
import Scramble from "./components/Scramble";
import TimeList from "./components/TimeList";
import TimeCard from "./components/TimeCard";
import Stats from "./components/Stats";
import VizHistogram from "./components/VizHistogram";
import VizPlot from "./components/VizPlot";
import MobileFooter from "./components/MobileFooter";
import Summary from "./components/Summary";
//import DesktopInfo from "./components/DesktopInfo";

import "./App.css";

// let testData = [
//   { key: "1", session: "1", item: "45.69" },
//   { key: "2", session: "1", item: "42.83" },
//   { key: "3", session: "1", item: "39.93" },
// ];

const App = (props) => {
  const [isActive, setIsActive] = useState(false);
  const [startTime, setStartTime] = useState();
  const [seconds, setSeconds] = useState(null);
  const [sessionTimes, setSessionTimes] = useState(null);
  const [thisScramble, setThisScramble] = useState();
  const [showTimer, setShowTimer] = useState(true);
  const [updateScramble,setUpdateScramble] = useState(false)

  if (sessionTimes === null) {
    // console.log("Getting local storage")
    const dls = localStorage.getItem("data");
    if (dls !== null) {
      // console.log("Found data")
      let x = JSON.parse(dls);
      // console.log(x)
      setSessionTimes(x);
    }
  }

  const fixLocalStorage = () => {
    let x = sessionTimes

    for(let i = 0; i < x.length; i++){
      x[i].id = x.length-i;
      x[i].key = x.length-i;
    }
    localStorage.setItem("data", JSON.stringify(x));
  }

  const addTime = () => {

    let newId = 1
    if(sessionTimes){
      newId = sessionTimes[0].id + 1;
    }

    // get fastest time
    console.log(sessionTimes)
    let fastestTime = 999999;
    if(sessionTimes){
      for(let i = sessionTimes.length-1; i>= 0; i--){
        if(sessionTimes[i].item < fastestTime){
          fastestTime = sessionTimes[i].item
        }
      }
    }

    // set first=true if the new time is fastest
    let ff = false;
    if(seconds < fastestTime){
      ff = true;
    }

    const newTime = {
      id: newId,
      key: newId,
      session: "1",
      item: seconds,
      dnf:false,
      penalty:false,
      target:false,
      first: ff,
      scramble:thisScramble[0],
    };    



    if (!sessionTimes) {
      setSessionTimes([newTime]);
      localStorage.setItem("data", JSON.stringify([newTime]));
    } else {
      setSessionTimes((prevTimes) => {
        localStorage.setItem("data", JSON.stringify([newTime, ...prevTimes]));
        return [newTime, ...prevTimes];
      });
    }
  };

  // Removes the most recently added element
  const removeTime = () => {
    if (sessionTimes === null) {
      return;
    }
    let n = sessionTimes.length;
    if (n === 1) {
      localStorage.removeItem("data");
      setSeconds(null);
      setSessionTimes(null);      
    } else {
      if (n > 1) {
        let x = sessionTimes.slice(1);
        setSeconds(null);
        setSessionTimes(x);
        localStorage.setItem("data", JSON.stringify(x));        
      }
    }
  };

  const resetSession = () => {

    if(window.confirm("Reset session? This will delete all times.")){
      console.log("reset")
      localStorage.removeItem("data");
      setSeconds(null);
      setSessionTimes(null);      
    }    

  };

  const markDNF = () => {

    let savedTime = sessionTimes[0];
    savedTime.dnf = true;
    let x = sessionTimes.slice(1);
    x = [savedTime, ...x]
    setSessionTimes(x)

  }

  const markPenalty = () => {
    let savedTime = sessionTimes[0];
    savedTime.penalty = true;
    let x = sessionTimes.slice(1);
    x = [savedTime, ...x]
    setSessionTimes(x)    

  }
  const toggleTimer = () => {
    setShowTimer(!showTimer);
  }


  const keyPressHandler = (e) => {
    e.preventDefault();
    console.log(e.code);

    if (e.code !== "Space") {

      if (e.code === "Delete" && !isActive) {
        removeTime();
        return;
      }
      if (e.code === "KeyR" && !isActive) {
        resetSession();
        return;
      }
      if (e.code === "KeyD" && !isActive) {
        markDNF();
        return;
      }    
      if (e.code === "KeyP" && !isActive) {
        markPenalty();
        return;
      }      
      if (e.code === "KeyH") {
        toggleTimer();
        return;
      }      

      if (e.code === "KeyQ" && !isActive) {
        fixLocalStorage();
        return;
      }      
      if (e.code === "KeyS" && !isActive) {
        setUpdateScramble(true);
        return;
      }

      return;
    }



    if (!isActive) {
      setIsActive(true);
      setStartTime(Date.now());
    } else {
      setIsActive(false);
      // console.log(seconds);
      addTime();
    }
  };

  //let showSeconds = "Solve";
  let showSeconds = seconds
  if (!isActive) {
    if (seconds === null) {
      showSeconds = null;
    } else {
      showSeconds = sessionTimes[0].item;
      // console.log(seconds)
    }
    //   showSeconds = seconds
  }

  const insertScramble = useCallback((newScram) => {
    setThisScramble(newScram);
    setUpdateScramble(false)
    // console.log("App.js 73 [new]:  " + newScram);
  }, []);

  // This is the timer!
  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => (Date.now() - startTime) / 1000);
      }, 5);
    } else if (isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, startTime, seconds]);

  const pointerHandler = (e) => {
    // console.log(e);
    if (!isActive) {
      setIsActive(true);
      setStartTime(Date.now());
    } else {
      setIsActive(false);
      // console.log(seconds);
      addTime();
    }
  };

  let timerClassName = "timer";
  if(!showTimer){
    timerClassName = "timer hide"
  }

  return (
    <Overlay>
      {/* <main id='main' tabIndex='0' onKeyDown={keyPressHandler} className="main"> */}
      <main
        id="main"
        tabIndex="0"
        onKeyUp={keyPressHandler}
        ref={(vv) => vv && vv.focus()}
      >
        <div className="container-left">
          <div className="identity">
            <Identity rmTime={removeTime} rmAll={resetSession} dnf={markDNF} penalty={markPenalty}/>
          </div>

          <div className="list">
            <TimeList data={sessionTimes} del={removeTime} />
          </div>


          <div className="stats">
            <Stats data={sessionTimes}></Stats>
          </div>
        </div>

        <div className="container-right">
          <div className="container-right-flex">
            <div className="scramble"   onPointerUp={pointerHandler}>
              <Scramble cts={isActive} update={updateScramble} sendToApp={insertScramble} />
              {/* <button onClick={keyPressHandler}>PRESS ME</button> */}
            </div>

            <div className={timerClassName} onPointerUp={pointerHandler}>
              <TimeCard tmSeconds={showSeconds} />

            </div>

            <div className="summary" onPointerUp={pointerHandler}>
              <Summary data={sessionTimes} solving={isActive}></Summary>
            </div>

            <div className="charts">
              <div className="plot">
                {/* <BoxPlot data={sessionTimes}></BoxPlot> */}
                {/* <PlotLine appData={sessionTimes}></PlotLine> */}
                <VizPlot data={sessionTimes}></VizPlot>
              </div>
              <div className="gram">
                {/* <Histogram data={sessionTimes}></Histogram> */}
                <VizHistogram data={sessionTimes}></VizHistogram>
              </div>
            </div>

            {/* The footer is only visible on mobile */}
            <MobileFooter data={sessionTimes} delete={removeTime} dnf={markDNF}></MobileFooter>
          </div>
        </div>
      </main>
    </Overlay>
  );
};

export default App;
