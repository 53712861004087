import React from "react";

import "./MobileFooter.css";

const fMean = (array) => {
  if (array.length > 0) {
    let u = array.reduce((a, v) => a + v) / array.length;
    return u;
  } else {
    return false;
  }
};

//zingi: https://stackoverflow.com/questions/7343890/standard-deviation-javascript
function fStdDev(array) {
  const n = array.length;
  const mean = array.reduce((a, b) => a + b) / n;
  return Math.sqrt(
    array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
  );
}

const movingAverage = (array, period) => {
  const n = array.length;
  if (n < period) {
    return null;
  } else {
    let sum = 0;
    // for (let i = n - 1; i >= n - period; i--) {
    for (let i = 0; i < 5; ++i) {
      sum += array[i];
    }
    return sum / period;
  }
};
// https://leancrew.com/all-this/2020/06/ordinal-numerals-and-javascript/
function ordinal(n) {
  var s = ["th", "st", "nd", "rd"];
  var v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

const MobileFooter = (props) => {
  let mean = "-";
  let stdev = "-";
  let topText = "---";
  let bestTime = "-";
  let slowTime = "-";
  let ma5 = "-";
  let ma12 = "-"

  // return <div>VVV</div>
  let timeShow;

  if (props.data !== null) {
    // console.log(props.data)
    let times = props.data.map((x) => x.item);
    const numSolves = times.length;
    times = times.filter((x) => x > 0);

    if (times.length > 0) {
      
      mean = fMean(times).toFixed(2);
      stdev = fStdDev(times).toFixed(2);

      const cTime = times[0];
      const timesSorted = [...times].sort((a, b) => a - b);
      const rank = timesSorted.indexOf(cTime) + 1;

      topText =
        "Solve #" +
        numSolves +
        ": " +
        cTime.toFixed(2) +
        " ~ " +
        ordinal(rank) +
        " fastest";

      bestTime = timesSorted[0].toFixed(2);
      slowTime = timesSorted[timesSorted.length - 1].toFixed(2);


      ma5 = props.data[0].ma5;
      if(ma5 < 0){
        ma5 = "-"
      }else{
        ma5 = ma5.toFixed(2)
      }
      ma12 = props.data[0].ma12;
      if(ma12 < 0){
        ma12 = "-"
      }else{
        ma12 = ma12.toFixed(2)
      }

      timeShow = props.data.map((x) => <div className="mf-item">{x.item}</div>);

    }
  }

  return (
    <div className="footer">
      <div className="footer-times">
      {timeShow}
      </div>
      
      <div className="footer-stats">
        <div className="top">{topText}</div>

        <div className="upper-left">
          <div className="footer-value">{mean}</div>
          <div className="footer-caption">Mean</div>
        </div>
        <div className="upper-right">
          <div className="footer-value">{ma5}</div>
          <div className="footer-caption">Mo5</div>
        </div>
        <div className="lower-left">
          <div className="footer-value footer-value-smaller"> {bestTime}</div>
          <div className="footer-caption">Fastest</div>
        </div>
        <div className="lower-right">
          <div className="footer-value">{ma12}</div>
          <div className="footer-caption">Mo12</div>
        </div>
      </div>

      <div className="footer-buttons">
        <button className="footer-button" onPointerDown={props.delete}>
          DEL
        </button>
        <button className="footer-button">T+2</button>
        <button className="footer-button" onPointerDown={props.dnf}>
          DNF
        </button>
        <button className="footer-button">VIEW</button>
      </div>
    </div>
  );
};
export default MobileFooter;
