import React from "react";
import TimeCard from "./TimeCard";
import "./Stats.css";

// also may try this... https://mathjs.org/index.html
// or this!  https://thisancog.github.io/statistics.js/inc/distributions.html

const fMean = (array) => {
  if (array.length > 0) {
    let u = array.reduce((a, v) => a + v) / array.length;
    return u;
  } else {
    return false;
  }
};

//zingi: https://stackoverflow.com/questions/7343890/standard-deviation-javascript
function fStdDev(array) {
  const n = array.length;
  const mean = array.reduce((a, b) => a + b) / n;
  return Math.sqrt(
    array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
  );
}

// Based on definition of 3rd moment
function fSkewness(array) {
  const n = array.length;
  const mean = array.reduce((a, b) => a + b) / n;
  const numer = array.map((x) => Math.pow(x - mean, 3)).reduce((a, b) => a + b) / n;
  const denom = fStdDev(array);
  return numer / Math.pow(denom, 3);
}

function fKurtosis(array) {
  const n = array.length;
  const mean = array.reduce((a, b) => a + b) / n;
  const numer = array.map((x) => Math.pow(x - mean, 4)).reduce((a, b) => a + b) / n;
  const denom = fStdDev(array);
  return numer / Math.pow(denom, 4);
}

//https://www.w3resource.com/javascript-exercises/fundamental/javascript-fundamental-exercise-88.php
const fMedian = (arr) => {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

// buboh: https://stackoverflow.com/questions/48719873/how-to-get-median-and-quartiles-percentiles-of-an-array-in-javascript-or-php
const fQuantile = (arr, q) => {
  const sorted = [...arr].sort((a, b) => a - b);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

// Bok-Mueller TRansform
// https://spin.atomicobject.com/2019/09/30/skew-normal-prng-javascript/
const randomNormals = (rng) => {
  let u1 = 0,
    u2 = 0;
  //Convert [0,1) to (0,1)
  while (u1 === 0) u1 = rng();
  while (u2 === 0) u2 = rng();
  const R = Math.sqrt(-2.0 * Math.log(u1));
  const Θ = 2.0 * Math.PI * u2;
  return [R * Math.cos(Θ), R * Math.sin(Θ)];
};

// Ian H: https://stackoverflow.com/questions/5259421/cumulative-distribution-function-in-javascript
function nCDF(x, mean, std) {
  var z = (x - mean) / std;
  var t = 1 / (1 + 0.2315419 * Math.abs(z));
  var d = 0.3989423 * Math.exp((-z * z) / 2);
  var prob = d * t * (0.3193815 + t * (-0.3565638 + t * (1.781478 + t * (-1.821256 + t * 1.330274))));
  //if( z > 0 ) prob = 1 - prob
  if (z < 0) prob = 1 - prob;
  return prob;
}

// const fSum = (array) => array.reduce((a, b) => a + b);
// https://www.geeksforgeeks.org/how-to-get-the-standard-deviation-of-an-array-of-numbers-using-javascript/

// https://leancrew.com/all-this/2020/06/ordinal-numerals-and-javascript/
function ordinal(n) {
  var s = ["th", "st", "nd", "rd"];
  var v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

const Stats = (props) => {
  let timeData, numSolves, ao5Min, ma5Min, ma12Min;
  if (props.data) {
    // console.log(props.data)
    const n = props.data.length;
    if (n > 0) {
      timeData = props.data.map((element) =>{
        let x = parseFloat(element.item);
        if(element.dnf){
          x = -2;
        }else{
          if(element.penalty){
            x += 2;
          }
        }
        return x;
      });

      const ao5Raw = props.data.map((element) => {
        let x;
        if(element.ao5 === "dnf"){
          x = -2;
        }else{
          x = parseFloat(element.ao5) + (element.penalty ? 2 : 0);
        }
        return x;
      });
      ao5Min = 999999;
      for (let i = 0; i < n; i++) {
        if (ao5Raw[i] > 0 && ao5Raw[i] < ao5Min) {
          ao5Min = ao5Raw[i];
        }
      }


      //const ma5Raw = props.data.map((element) => parseFloat(element.ma5));
      const ma5Raw = props.data.map((element) => {
        let x;
        if(element.dnf || element.ma5 === -1){
          x = -1;
        }else{
          x = parseFloat(element.ma5) + (element.penalty ? 2 : 0);
        }
        return x;
      });
      
      ma5Min = 999999;
      for (let i = 0; i < n; i++) {
        if (ma5Raw[i] > 0 && ma5Raw[i] < ma5Min) {
          ma5Min = ma5Raw[i];
        }
      }

      //const ma12Raw = props.data.map((element) => parseFloat(element.ma12));
      const ma12Raw = props.data.map((element) => {
        let x;
        if(element.dnf || element.ma12 === -1){
          x = -1;
        }else{
          x = parseFloat(element.ma12) + (element.penalty ? 2 : 0);
        }

        return x;
      });

      ma12Min = 999999;
      for (let i = 0; i < ma12Raw.length; i++) {
        if (ma12Raw[i] > 0 && ma12Raw[i] < ma12Min) {
          ma12Min = ma12Raw[i];
        }
      }

      numSolves = n;
    } else {
      return <div>...</div>;
    }
  } else {
    return <></>;
  }



  timeData = timeData.filter((x) => x > 0);

  if (timeData.length === 0) {
    return <div></div>;
  }

  const mean = fMean(timeData);
  const stdv = fStdDev(timeData);
  const cvar = ((100 * stdv) / mean).toFixed(0) + "%";
  const min = Math.min(...timeData);
  const max = Math.max(...timeData);
  const rnge = (max - min);
  const mid = fMedian(timeData);
  const skw = fSkewness(timeData);
  const kur = fKurtosis(timeData);
  const q1 = fQuantile(timeData, 0.25);
  const q3 = fQuantile(timeData, 0.75);
  const iqr = (q3 - q1);
  const z = ((timeData[0] - mean) / stdv);
  const norm = (1 - nCDF(timeData[0], mean, stdv));

  const currentTime = timeData[0];

  //use spread operator otherwise sort is 'in place'
  const timeDataSorted = [...timeData].sort((a, b) => a - b);
  const rank = timeDataSorted.indexOf(currentTime) + 1;

  let target = (mean - stdv);
  if(target <= 0){
    target = 0.75 * q1 //arbitrarily use 75% of the first quartile
  }

  const tma = props.data
  if(tma.length >= 1){
    tma[0]["target"] = target;   //adds new key/value pair to the props object
  }
  // console.log(tma);

  const fmt = (x) =>{
    if(x > 0 && x < 60 ){
      return x.toFixed(2)
    }else{
      if(x == 999999){
        return "-"
      }else{
        return x.toFixed(0)
      }
    }
  }

  let confLower = mean - (1.96 * stdv) / Math.sqrt(numSolves);
  let confUpper = +mean + (1.96 * stdv) / Math.sqrt(numSolves);
  let confInterval = "na"
  if(confLower > 0){
    confInterval = fmt(confLower) + " ... " + fmt(confUpper)
  }





  return (
    <div className="stats-tables">
      <table className="stats-table">
        <tbody>
        <tr>
          <td className="stats-table-header">#{numSolves}</td>
          <td className="stats-table-header fast">Best</td>
          <td className="stats-table-header fast">Ao5</td>
          <td className="stats-table-header fast">Mo5</td>
          <td className="stats-table-header fast">Mo12</td>
        </tr>
        <tr>
          <td className="stats-table-item">{fmt(currentTime)}</td>
          <td className="stats-table-item">{fmt(min)}</td>
          <td className="stats-table-item">{fmt(ao5Min)}</td>
          <td className="stats-table-item">{fmt(ma5Min)}</td>
          <td className="stats-table-item">{fmt(ma12Min)}</td>
        </tr>        

        <tr>
          <td className="stats-table-header">Mean</td>
          <td className="stats-table-header">StdDev</td>
          <td className="stats-table-header">CV</td>
          <td className="stats-table-header" colSpan="2">ConfInt</td>
        </tr>
        <tr>
          <td className="stats-table-item">{fmt(mean)}</td>
          <td className="stats-table-item">{fmt(stdv)}</td>
          <td className="stats-table-item">{cvar}</td>

          <td className="stats-table-item" colSpan="2">
          {confInterval}
          </td>

        </tr>

        <tr>
          <td className="stats-table-header">Median</td>
          <td className="stats-table-header">Q1</td>
          <td className="stats-table-header">Q3</td>
          <td className="stats-table-header">IQR</td>
          <td className="stats-table-header">Range</td>
        </tr>
        <tr>
        <td className="stats-table-item">{fmt(mid)}</td>
        <td className="stats-table-item">{fmt(q1)}</td>
        <td className="stats-table-item">{fmt(q3)}</td>
        <td className="stats-table-item">{fmt(iqr)}</td>
        <td className="stats-table-item">{fmt(rnge)}</td>
        </tr>

        <tr>
          <td className="stats-table-header">Max</td>
          <td className="stats-table-header">Skw</td>
          <td className="stats-table-header">Kur</td>
          <td className="stats-table-header">CDF</td>
          <td className="stats-table-header">Target</td>
        </tr>
        <tr>
        <td className="stats-table-item">{fmt(max)}</td>
        <td className="stats-table-item">{fmt(skw)}</td>
        <td className="stats-table-item">{fmt(kur)}</td>
        <td className="stats-table-item">{fmt(norm)}</td>
        <td className="stats-table-item">{fmt(target)}</td>

        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(Stats); //memo prevents this component from running every time
