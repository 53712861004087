import React from "react";
import TimeCard from "./TimeCard";

// https://leancrew.com/all-this/2020/06/ordinal-numerals-and-javascript/
function ordinal(n) {
  var s = ["th", "st", "nd", "rd"];
  var v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

const Summary = (props) => {
  let n, rawData, ao5Data, ma5Data, ma12Data;
  if (props.data) {
    n = props.data.length;
    rawData = props.data.map((elx) => {
      let x = parseFloat(elx.item) + (elx.penalty ? 2 : 0);
      return x;
    });
    ao5Data = props.data.map((elx) => parseFloat(elx.ao5));
    ma5Data = props.data.map((elx) => parseFloat(elx.ma5));
    ma12Data = props.data.map((elx) => parseFloat(elx.ma12));
  } else {
    return <div>Accurate timing, statistics, data visualization</div>;
  }

  //rawData = rawData.filter(x => x > 0);
  // const rawSorted = [...rawData].sort((a, b) => a - b);
  // const rank = rawSorted.indexOf(rawData[0]) + 1;

  let rawFiltered = rawData.filter((x)=>x > 0);
  const rawSorted = [...rawFiltered].sort((a, b) => a - b);
  const rank = rawSorted.indexOf(rawData[0]) + 1;

  let ordn = ordinal(rank);

  let lastValue = rawData[0];
  if(lastValue === -2){
    lastValue = "DNF";
    ordn = "";
  }else{

    lastValue = <TimeCard tmSeconds={lastValue}></TimeCard>
    //lastValue = <TimeCard tmSeconds='43'></TimeCard>
  }

  let lastAo5 = <TimeCard tmSeconds={ao5Data[0]}></TimeCard>
  let lastMo5 = <TimeCard tmSeconds={ma5Data[0]}></TimeCard>

  

  if(props.solving){
    return <React.Fragment>S O L V E</React.Fragment>
  }
  

  return <React.Fragment>
    <div className="summary-count">Solve #{n}: {lastValue}</div>
    <div className="summary-rank">{ordn}</div>
    <div className="summary-time">Ao5: {lastAo5}</div>
    <div className="summary-time">Mo5: {lastMo5}</div>
    
  </React.Fragment>;
};

export default Summary;
