import React from "react";

import "./TimeListCard.css";


const TimeListCard = (props) => {



  if (props.tmSeconds === "dnf") {
    return <div id={props.id} className="timelist-card">DNF</div>
  }  
  if (props.tmSeconds === -1) {
    return <div id={props.id} className="timelist-card">---</div>
  }

  const formatTime = (sx) => {

    if (sx < 0) {
      return "<0";
    }
    if (sx < 60) {
      return sx.toFixed(2);
    } else {
      if (sx < 120) {
        let m = parseInt(sx / 60);
        let s = sx - 60 * m;
        if (s < 10) {
          return m + ":0" + s.toFixed(1);
        } else {
          return m + ":" + s.toFixed(1);
        }
      } else {
        if (sx < 3600) {
          let m = parseInt(sx / 60);
          let s = sx - 60 * m;
          if (s < 10) {
            return m + ":0" + s.toFixed(0);
          } else {
            return m + ":" + s.toFixed(0);
          }
        } else {
          let dtm = new Date(0);
          dtm.setSeconds(sx); // specify value for SECONDS here
          return dtm.toISOString().substr(11, 8);

        }
      }
    }
  };

  let formattedTime = formatTime(+props.tmSeconds);
  return <div id={props.id} className="timelist-card">{formattedTime}</div>

//  return <div className="timelist-card">{props.tmSeconds}</div>

};
export default TimeListCard;
