import React from "react";

import {
  XYChart,
  // PatternLines,
  CrossHair,
  // AreaSeries,
  // AreaDifferenceSeries,
  HorizontalReferenceLine,
  PointSeries,
  LinearGradient,
  XAxis,
} from "@data-ui/xy-chart";

// import colors from "@data-ui/theme/lib/color";
import "./VizPlot.css";
import LineSeries from "@data-ui/xy-chart/lib/series/LineSeries";
import YAxis from "@data-ui/histogram/lib/axis/YAxis";

//https://www.w3resource.com/javascript-exercises/fundamental/javascript-fundamental-exercise-88.php
const fMedian = (arr) => {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

const VizPlot = (props) => {

  let n;
  if (props.data) {
    n = props.data.length;
    if (n <= 0) {
      return <div></div>;
    }
  } else {
    return <div></div>;
  }

  // Display only the most recent 25 datapoints
  if(n > 25){
    n = 25
  }
  
 
    
  // const rawData = props.data.map((elx) => {
  //     return parseFloat(elx.item)
  // });

  let rawData = props.data.map((element) =>{
    let x = parseFloat(element.item);
    if(element.dnf){
      x = -2;
    }else{
      if(element.penalty){
        x += 2;
      }
    }
    return x;
  });

  let qq = rawData.filter(x => x > 0);
  if(qq.length === 0){
    return <div></div>
  }



  // Median but exclude values less than or equal to zero. i.e., DNF 
  const median = fMedian(rawData.filter(x => x > 0))

  

  let xyData = [];

  for (let i = 0; i < n; i++) {
    let revX = n - i;

    let y = props.data[i].item + (props.data[i].penalty ? 2 : 0);
    if(props.data[i].dnf){
      y = median
    }

    xyData.push({ x: revX, y: y });
  }

  // console.log(xyData)

  let ma5Data = [];
  for (let i = 0; i < n; i++) {
    let revX = n - i;
    let yval = props.data[i].ma5 === -1  ? undefined : props.data[i].ma5;
    ma5Data.push({ x: revX, y: yval });
  }
  let ma12Data = [];
  for (let i = 0; i < n; i++) {
    let revX = n - i;
    let yval = props.data[i].ma12 === -1 ? undefined : props.data[i].ma12;
    ma12Data.push({ x: revX, y: yval });
  }
    // console.log(xyData);
    // console.log(ma5Data);
    // console.log(ma12Data);

  return (

    <div className="timelines">

    <XYChart
      className="timelines"
      ariaLabel="Description"
      eventTrigger="series"
      xScale={{ type: "linear" }}
      yScale={{ type: "linear", includeZero: false }}
      snapTooltipToDataX
      width={380}
      height={230}
      margin={{ top: 40, right: 10, bottom: 40, left: 40 }}
      valueAccessor={(datum) => datum}
      showXGrid
      showYGrid
    >
      <XAxis numTicks={n} hideZero={true} />
      <YAxis />
      <LinearGradient
        id="aqua_lightaqua_gradient"
        to="#faa2c1"
        from="#e64980"
      />

      <PointSeries
        data={xyData}
        fill="#000"
        fillOpacity={0.3}
        stroke="#be4bdb"
      />
      <LineSeries data={ma12Data} stroke={"#d0d"} />
      <LineSeries data={ma5Data} stroke={"#00f"} />
      <LineSeries data={xyData} stroke={"#000"} />

      <HorizontalReferenceLine
        stroke={"#888"}
        strokeWidth={1}
        strokeDasharray="4 4"
        reference={median}
      />

      <CrossHair showHorizontalLine={true} />
    </XYChart>
    </div>
  );
};

export default React.memo(VizPlot);
