import React from "react";

import "./TimeCard.css";
// import logo from "../images/cube45.png";

// import Spinner from "./UI/Spinner";

const TimeCard = (props) => {
  
  if (!props.tmSeconds || props.tmSeconds === null) {
    return <div className="timer-timecard">geTimer</div>;
  } else {
    if (props.tmSeconds === "Solve") {
      return <div className="timer-timecard">Solve</div>;
    }
  }
  if(props.tmSeconds === "-"){
    return <div className="timer-timecard">-</div>;
  }
  

  let ts = props.tmSeconds;
  
  const formatTime = (sx) => {
    if (sx < 0) {
      return "-";
    }
    if (sx < 60) {
      return sx.toFixed(2);
    } else {
      if (sx < 120) {
        let m = parseInt(sx / 60);
        let s = sx - 60 * m;
        if (s < 10) {
          return m + ":0" + s.toFixed(1);
        } else {
          return m + ":" + s.toFixed(1);
        }
      } else {
        if (sx < 3600) {
          let m = parseInt(sx / 60);
          let s = sx - 60 * m;
          if (s < 10) {
            return m + ":0" + s.toFixed(0);
          } else {
            return m + ":" + s.toFixed(0);
          }
        } else {
          let dtm = new Date(0);
          dtm.setSeconds(sx); // specify value for SECONDS here
          return dtm.toISOString().substr(11, 8);
        }
      }
    }
  };

  let formattedTime = formatTime(+props.tmSeconds);

  // return <React.Fragment>{formattedTime}</React.Fragment>;
  return <div className="timer-timecard">{formattedTime}</div>
  // return <div>14:37.9</div>
};
export default TimeCard;
