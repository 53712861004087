import React from "react";
import "./Modal.css";

const Modal = (props) => {
  return (
    <React.Fragment>
      <div className="backdrop"/>
      <div className="modal"  onPointerDown={props.hide}>
        
        <div className="modal-menu">
          <div className="modal-menu-header">geTimer</div>
          <div className="modal-menu-item" onPointerDown={props.delete}>DELETE</div>
          <div className="modal-menu-item" onPointerDown={props.penalty}>T+2s</div>
          <div className="modal-menu-item" onPointerDown={props.dnf}>DNF</div>
          <div className="modal-menu-item" onPointerDown={props.reset}>RESET</div>
          <div className="modal-menu-item" onPointerDown={props.hide}>CANCEL</div>
        </div>
        <div className="desktop-info">
        <ul>
          <li>
            <b>Desktop shortcuts</b>
          </li>
          <li>[space]: start/stop</li>
          <li>[del]: delete most recent time</li>
          <li>[d]: DNF - did not finish</li>
          <li>[p]: 2 second penalty</li>
          <li>[r]: reset session (delete all times)</li>
          <li>[h]: hide/unhide timer</li>
        </ul>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Modal; //used by Identity.js
