import React, { useState, useEffect } from "react";
import "./Scramble.css";

const Scrambo = require("scrambo"); // https://github.com/nickcolley/scrambo
const cube3x3 = new Scrambo().type("333"); //222,333,444,555,666,777,clock,minx,pyram,sq1,skewb
const cube2x2 = new Scrambo().type("222"); //222,333,444,555,666,777,clock,minx,pyram,sq1,skewb

console.log(cube3x3.get(1));
console.log(cube2x2.get(1));

const Scramble = (props) => {
  // console.log("Scramble " + props.cts);

  const { cts, sendToApp, update } = props; // destructure props
  const [myScramble, setMyScramble] = useState();

  useEffect(() => {
    if (cts === false || update === true) {
      const x3 = cube3x3.get(1);
      setMyScramble(x3);
      sendToApp(x3); //sending the current scramble
    }
  }, [cts, sendToApp, update]);

  // if (cts === false) {
  //   return <div>{myScramble}</div>;
  // } else {
  //   return <div>solve</div>;
  // }
  return <div className="scramble-text">{myScramble}</div>;
};
export default React.memo(Scramble);
