import "./Overlay.css";

const Overlay = (props) => {

  // return props.children;

  return(
    <div className="overlay">
      {props.children}
    </div>
  )
};
export default Overlay;
