import React, { useState } from "react";
import "./Identity.css";
import cubeImg from "../images/cube45.png";

import Modal from "./UI/Modal";

// NOTE!!  Parent component for props is Identity.js
const Identity = (props) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleShowMenu = () => {
    console.log("showMenu start");
    setShowMenu(true);
  };

  const handleHideMenu = () => {
    setShowMenu(false);
    console.log("handleHideMenu");
  };



  return (
    <React.Fragment>
      {showMenu && (
        <Modal
          hide={handleHideMenu}
          penalty={props.penalty}
          delete={props.rmTime}
          dnf={props.dnf}
          reset={props.rmAll}
        />
      )}
      <div className="menu">
        <div id="menuToggle" onPointerDown={handleShowMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div className="title">geTimer</div>
        
      </div>
      {/* <div className="menu" onPointerDown={handleShowMenu}>
            <img className="cube" src={cubeImg} alt="speedcubing is fun!"></img>
            <div className="title">
                geTimer
                <div className="version">Ver 1.0</div>
            </div>
        </div> */}
    </React.Fragment>
  );
};
export default Identity; //used by App.js
