import React from "react";
import "./VizHistogram.css";

import {
  Histogram,
  LinearGradient,
  BarSeries,
  XAxis,
  YAxis,
} from "@data-ui/histogram";

const VizHistogram = (props) => {
  let rawData, n;
  if (props.data) {
    n = props.data.length;
    if (n > 0) {
      rawData = props.data.map((element) =>{
        let x = parseFloat(element.item);
        if(element.dnf){
          x = -2;
        }else{
          if(element.penalty){
            x += 2;
          }
        }
        return x;
      });
    } else {
      return <div></div>;
    }
  } else {
    return <div></div>;
  }

  //rawData = Array(34).fill().map(Math.random);
  // console.log(rawData);

  rawData = rawData.filter(x => x > 0);
  n = rawData.length;
  if(n === 0){
    return <div></div>
  }

  let nBins = 3;
  if (n > 10) {
    nBins = Math.floor(1 + 3.222 * Math.log10(n)); // Sturge's Rule
  }

  return (
    <div className="dataui-histogram">
      <Histogram
        width={380}
        height={230}

        margin={{ top: 40, right: 10, bottom: 40, left: 40 }}
        ariaLabel="Histogram of Solve Times"
        orientation="vertical"
        cumulative={false}
        normalized={false}
        binCount={nBins}
        valueAccessor={(datum) => datum}
        binType="numeric"
        
        renderTooltip={({ event, datum, data, color }) => (
          <div>
            <strong style={{ color }}>
              {datum.bin0} to {datum.bin1}
            </strong>
            <div>
              <strong>Solves: </strong>
              {datum.count}
            </div>
            <div>
              <strong>Percent: </strong>
              {((100 * datum.count) / n).toFixed(0)}
            </div>
            {/* <div>
              <strong>density </strong>
              {datum.density}
            </div> */}
          </div>
        )}
      >
        {/* <LinearGradient
          id="bar-series"
          from="#000"
          to="#fff"
          fromOpacity={1}
          rotate={0}
          vertical={true}
        /> */}
        <BarSeries animated rawData={rawData} fill="#1d4774" stroke="#1d4774"/>

        {/* <DensitySeries
          stroke="#000"
          strokeWidth={1}
          showArea={false}
          kernel="gaussian"
          rawData={rawData}
          useEntireScale={true}
          showLine={true}
          animated={true}
        /> */}

        <XAxis />
        <YAxis label="solves" />
      </Histogram>
    </div>
  );
};

export default React.memo(VizHistogram);

// SAVED AND WORKS
